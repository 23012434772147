var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Case, Default, Switch } from 'react-when-then';
import { IStatus } from '../../../@types/status';
import ContainerLayout from '../../../components/ContainerLayout';
import { LinearLoader } from '../../../components/Loader';
import { getOneLevel } from '../../../redux/slices/levels/actions';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import LevelForm from '../../../sections/@dashboard/Configuration/Levels/LevelForm';
var LevelsView = function () {
    var id = useParams().id;
    useEffect(function () {
        dispatch(getOneLevel(id || ''));
    }, [dispatch, id]);
    var _a = useSelector(function (store) { return store.levels; }), level = _a.level, status = _a.status;
    var isLoading = status === IStatus.LOADING;
    return (_jsx(ContainerLayout, __assign({ helmet: "Level", heading: "View Level", links: [
            {
                name: 'level',
                href: PATH_DASHBOARD.configuration.levels.root,
            },
            { name: 'View Level' },
        ] }, { children: _jsxs(Switch, { children: [_jsx(Case, __assign({ when: isLoading }, { children: _jsx(LinearLoader, {}) })), _jsx(Default, { children: _jsx(LevelForm, { isView: true, currentLevel: level || undefined, isEdit: false }, "".concat(level === null || level === void 0 ? void 0 : level._id, "___").concat(new Date())) })] }) })));
};
export default LevelsView;
